<template>
  <div class="pt-3">
    <h3 class="text-dark" style="opacity: 0.8">行业指数变动情况</h3>

    <div class="mt-2" v-show="!tableLoading">
      <div>
        <a
          class="pr-4"
          v-for="item in tabList"
          :key="item.url"
          :ripple="true"
          @click="currentTab = item.url"
          style="background-color: transparent; width: auto"
          :elevation="0"
        >
          <span
            class="pa-0"
            :class="{
              'active-tab': currentTab == item.url,
              'un-active-tab': currentTab != item.url,
            }"
            >{{ item.title }}</span
          >
        </a>
      </div>

      <vxe-table
        :data="tableData"
        style="width: 100%; margin-top: 20px"
        class="mytable-scrollbar"
        :border="false"
        resizable
        :scroll-y="{ enabled: false }"
        show-overflow
        show-header-overflow
        highlight-hover-row
        ref="chartTable"
        max-height="100000"
        size="small"
        :empty-render="{ name: 'NotData' }"
        align="left"
      >
        <vxe-column fixed="left" min-width="80" field="name" title="行业名称">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ row.name ? row.name : "-" }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="排名变动率"
          field="rate_of_change"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ $stockHelpers.getPercent(row.rate_of_change) }}
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="排名变动情况" field="MOM_3D">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ row.T14 }}名 -> {{ row.T0 }}名
            </div>
          </template>
        </vxe-column>
      </vxe-table>

      <div
        :id="typeId + 'RankTable'"
        style="
          height: 335px;
          width: calc(100vw - 170px);
          z-index: 10;
          margin-top: 10px;
        "
      ></div>
    </div>
    <div v-if="tableLoading" class="mt-2">
      <v-skeleton-loader
        v-for="item in 2"
        v-bind="attrs"
        type="article"
        :key="item"
      >
      </v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
export default {
  name: "MomentumRank",
  components: {},
  props: {
    typeId: "",
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
      tableLoading: false,

      currentTab: 0,
      tabList: [
        {
          title: "1日动量",
          url: 0,
        },
        {
          title: "3日动量",
          url: 1,
        },
        {
          title: "5日动量",
          url: 2,
        },
        {
          title: "10日动量",
          url: 3,
        },
        {
          title: "20日动量",
          url: 4,
        },
        {
          title: "60日动量",
          url: 5,
        },
      ],

      MOM_typeArr: [
        "MOM_1D",
        "MOM_3D",
        "MOM_5D",
        "MOM_10D",
        "MOM_20D",
        "MOM_60D",
      ],
      MOM_type: "MOM_1D",

      tableData: [],
      chartData: [],
      myChart: {},
    };
  },
  mounted() {
    // setTimeout(() => {

    // }, 1000);
    this.getData();

    let chartDom = document.getElementById(this.typeId + "RankTable");
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
  },
  methods: {
    getData() {
      this.tableLoading = true;
      ResonanceContrast.getIndustryChange(this.typeId)
        .then((res) => {
          //console.log(res.data);
          this.chartData = res.data;
          this.renderChart();

          this.tableData =
            this.chartData[this.MOM_type.toLowerCase()].plate_ranks;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    renderChart() {
      let renderData = this.chartData[this.MOM_type.toLowerCase()];
      //console.log(renderData.series);
      let series = renderData.series.map((item) => {
        return {
          name: item.name,
          type: "parallel",
          data: item.data,
          lineStyle: {
            width: 2,
            opacity: 0.8,
          },
          emphasis: {
            focus: "series",
          },
        };
      });

      let option = {
        grid: {
          top: 20,
          bottom: 20,
        },
        legend: {
          ...renderData.legend,
          top: 10,
          itemGap: 20,
          padding: 10,
          textStyle: {
            color: "#333",
            fontSize: 14,
          },
        },
        backgroundColor: "#fff",
        parallel: {
          top: 70,
          left: 10,
        },
        tooltip: {
          padding: 10,
          borderWidth: 1,
          formatter: function (params, ticket, callback) {
            //console.log(params);
            return `
              行业: ${params.name}<br/>
              排名变动率: ${(params.data[15] * 100).toFixed(2)}%<br/>
            `;
          },
        },
        parallelAxis: renderData.parallelAxis,
        series: series,
      };

      this.myChart.setOption(option);
    },
  },
  watch: {
    currentTab(val) {
      this.MOM_type = this.MOM_typeArr[val];

      this.renderChart();
      this.tableData = this.chartData[this.MOM_type.toLowerCase()].plate_ranks;
    },
  },
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
