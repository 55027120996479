<template>
  <div>
    <LoadingPage
      :visible="tableLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <div class="px-0 py-1" v-show="!tableLoading">
      <div class="d-flex">
        <h3 class="text-dark" style="opacity: 0.8">概念指数排名</h3>

        <div
          style="z-index: 11; position: absolute; width: 100%; top: 10px"
          class="d-flex justify-center"
        >
          <a
            class="pr-4"
            v-for="item in barTabList"
            :key="item.url"
            :ripple="true"
            @click="currentBarTab = item.url"
            style="background-color: transparent; width: auto"
            :elevation="0"
          >
            <span
              class="pa-0"
              :class="{
                'active-tab': currentBarTab == item.url,
                'un-active-tab': currentBarTab != item.url,
              }"
              >{{ item.title }}</span
            >
          </a>
        </div>

        <vxe-button
          @click="isTable = !isTable"
          size="small"
          :content="isTable ? '切换到图表显示' : '切换到表格显示'"
          status="my-danger"
          class="ml-5"
          style="z-index: 12"
        ></vxe-button>
      </div>

      <ChartWithTable
        :currentBarTab="currentBarTab"
        :barData="barData"
        :isTable="isTable"
        :momentumDetail="momentumDetail"
        @selectItem="selectItem"
        :typeId="this.typeId"
      ></ChartWithTable>

      <MomDetailTable
        :momentumComposition="momentumComposition"
        v-show="momentumComposition.length != 0"
        :currentBarTab="currentBarTab"
      ></MomDetailTable>

      <MomTimeLine
        :typeId="typeId"
        :currentBarTab="currentBarTab"
      ></MomTimeLine>

      <TagHeatChart :typeId="typeId"></TagHeatChart>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import ChartWithTable from "@/components/ResonanceContrast/Momentum/ChartWithTable";
import MomDetailTable from "@/components/ResonanceContrast/Momentum/MomDetailTable";
import TagHeatChart from "@/components/ResonanceContrast/Momentum/TagHeatChart";
import MomTimeLine from "@/components/ResonanceContrast/Momentum/MomTimeLine.vue";
export default {
  name: "Zhongxinerji",
  components: { ChartWithTable, MomDetailTable, TagHeatChart, MomTimeLine },
  data() {
    return {
      tableLoading: false,
      isTable: false,

      currentBarTab: 0,
      barTabList: [
        {
          title: "1日动量",
          url: 0,
        },
        {
          title: "3日动量",
          url: 1,
        },
        {
          title: "5日动量",
          url: 2,
        },
        {
          title: "10日动量",
          url: 3,
        },
        {
          title: "20日动量",
          url: 4,
        },
        {
          title: "60日动量",
          url: 5,
        },
      ],

      barData: [],
      momentumDetail: [],
      momentumComposition: [],
      typeId: "pool",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      ResonanceContrast.getMomentum(this.typeId)
        .then((res) => {
          //console.log(res.data);
          this.barData = res.data.mom;
          this.momentumDetail = res.data.list;
          this.$emit("getUpdateTime", res.data.list[0].created_at);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    selectItem(item) {
      //console.log(this.typeId);
      this.showLoading("获取成分明细中，请稍候");
      ResonanceContrast.getMomentumComposition(item, this.typeId)
        .then((res) => {
          //console.log(res.data);
          this.momentumComposition = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
  watch: {},
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
