<template>
  <div>
    <div
      id="tagFanChart"
      style="
        height: calc(100vh - 250px);
        width: calc(100vw - 170px);
        z-index: 10;
      "
      v-if="!isTagIndex"
    ></div>
    <div
      id="tagFanChart"
      style="
        height: calc(100vh - 250px);
        width: calc(100vw - 700px);
        z-index: 10;
      "
      v-if="isTagIndex"
    ></div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import FanChartTagChild from "./FanChartTagChild.vue";
export default {
  name: "FanChart",
  components: {},
  props: {
    currentBarTab: 0,
    barData: [],
    momentumDetail: [],
    typeId: "",
    isTagIndex: false,
    fanDays: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      myChart: null,
      chartData: [],
      legends: [],
    };
  },
  mounted() {
    let chartDom = document.getElementById("tagFanChart");
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
    this.getChartData();
    this.myChart.getZr().on("click", (params) => {
      //console.log(params.topTarget.type);
      if (params.topTarget) {
        //这里折线图的线和点是不同组件，获取seriesIndex方法不同
        let seriesIndex;
        //点击线
        if (params.topTarget.type === "ec-polyline") {
          seriesIndex = params.topTarget.parent.parent.__ecComponentInfo.index;
        }
        //点击拐点
        else if (params.topTarget.type === "path") {
          //console.log(params.target.parent.parent);
          seriesIndex =
            params.target.parent.parent.parent.__ecComponentInfo.index;
        }
        //点击markline
        else if (params.topTarget.type === "ec-line") {
          //console.log(params.target.parent.parent.parent);
          if (params.target.parent.parent.__ecComponentInfo.index) {
            seriesIndex = params.target.parent.parent.__ecComponentInfo.index;
          } else {
            seriesIndex =
              params.target.parent.parent.parent.__ecComponentInfo.index;
          }
        }
        //console.log(seriesIndex);
        //得到点击的seriesIndex就可以写业务代码了
        if (seriesIndex !== false) {
          this.$layer.iframe({
            content: {
              content: FanChartTagChild,
              parent: this,
              data: {
                iframeData: {
                  race_name: this.chartData[seriesIndex][0].race_name,
                },
              },
            },
            area: ["800px", "600px"],
            title: this.chartData[seriesIndex][0].race_name + "--标签累计涨幅",
            maxmin: true,
            shade: true,
            shadeClose: false,
            cancel: () => {
              //关闭弹窗事件
            },
          });
        }
      }
    });

    this.myChart.on("legendselectchanged", (obj) => {
      console.log(obj);
      //点击之后所有被选中的图例
      const selectedobj = Object.keys(obj.selected).filter(
        (item) => obj.selected[item]
      );
      //点击的图例是否在 所有被选中的图例 中，也就是判断当前点击是选中操作还是取消操作
      const flag = selectedobj.indexOf(obj.name) > -1;
      //当是取消操作 && 取消前所有图例都是选中状态
      //选中当前图例，取消选中剩余图例
      if (!flag && selectedobj.length + 1 === this.legends.length) {
        for (let i = 0; i < this.legends.length; i++) {
          // 显示当前legend 关闭非当前legend
          if (obj.name === this.legends[i].name) {
            this.myChart.dispatchAction({
              type: "legendSelect",
              name: this.legends[i].name,
            });
          } else {
            this.myChart.dispatchAction({
              type: "legendUnSelect",
              name: this.legends[i].name,
            });
          }
        }
      }
      //当是取消操作 && 取消后就没有图例是选中状态
      //选中所有图例
      if (!flag && !selectedobj.length) {
        for (let i = 0; i < this.legends.length; i++) {
          this.myChart.dispatchAction({
            type: "legendAllSelect",
          });
        }
      }
    });
  },
  methods: {
    getChartData() {
      this.tableLoading = true;
      ResonanceContrast.getFanChartTag({ per: this.fanDays })
        .then((res) => {
          // //过滤数组长度小于3的数据
          // this.chartData = res.data.filter((item) => item.length > 3);

          // this.chartData.forEach((item, index) => {
          //   //筛选，只保留最后fanDays天的数据
          //   this.chartData[index] = item.slice(-this.fanDays);
          // });
          this.chartData = res.data;
          this.initChart();
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    initChart() {
      let series = [];
      this.legends = [];
      this.chartData.forEach((item) => {
        this.legends.push({
          name: item[0].race_name,
          icon: "circle",
        });
        //console.log(item);
        let fanData = [];
        item.forEach((item2, index) => {
          if (index < item.length - 1) {
            fanData.push({
              value: [item2.relative_strength, item2.relative_strength_mom],
              trade_date: item2.trade_date,
              race_name: item2.race_name,
              tag: item2.trend_tag,
            });
          } else {
            fanData.push({
              value: [item2.relative_strength, item2.relative_strength_mom],
              trade_date: item2.trade_date,
              race_name: item2.race_name,
              tag: item2.trend_tag,
              symbolSize: 2,
            });
          }
        });

        let currSeries = {
          name: item[0].race_name,
          type: "line",
          // color: [
          //   '#096631',
          //   '#0f86c3',
          //   '#ffdf01',
          //   '#dd801e',
          //   '#b84747',
          //   '#9758e0',
          //   '#eecf85',
          //   '#0c424b',
          //   '#31baff',
          //   '#533527',
          //   '#7dc9ce',
          //   '#2ec2d7',
          // ],
          data: fanData,
          markLine: {
            zlevel: 0,
            silent: true,
            data: [
              [
                {
                  coord: [
                    item[item.length - 2].relative_strength,
                    item[item.length - 2].relative_strength_mom,
                  ],
                },
                {
                  coord: [
                    item[item.length - 1].relative_strength,
                    item[item.length - 1].relative_strength_mom,
                  ],
                },
              ],
            ],
            symbol: ["none", "arrow"],
            emphasis: { disabled: false },
          },
          emphasis: {
            focus: "series",
            blurScope: "coordinateSystem",
          },
          tooltip: {
            padding: 10,
            borderWidth: 1,
            // triggerOn: 'none', //* 提示框触发的条件,这个配置会覆盖echarts默认的鼠标事件*
            trigger: "item",
            formatter: (params) => {
              return `
              <b>标签</b> : ${params.data.race_name}<br/>
              <b>趋势</b> : ${params.data.tag}<br/>
              <b>相对强弱</b> : ${params.data.value[0].toFixed(2)}<br/>
              <b>相对强弱动量</b> : ${params.data.value[1].toFixed(2)}<br/>
              <b>日期</b> : ${params.data.trade_date}<br/>
              `;
            },
          },
        };
        series.push(currSeries);
      });

      let option = {
        xAxis: {
          type: "value",
          scale: false,
        },
        yAxis: {
          type: "value",
          scale: false,
        },
        series: series,
        legend: {
          type: "scroll",
          data: this.legends,
          zlevel: 100,
          right: "10px",
          top: "20px",
          orient: "vertical",
        },
        tooltip: {
          padding: 10,
          borderWidth: 1,
          // triggerOn: 'none',
          trigger: "item",
          formatter: (params) => {
            return "123";
          },
        },
        grid: {
          top: "20px",
          left: "20px",
          right: "200px",
          bottom: "20px",
          containLabel: true,
        },
        graphic: [
          {
            type: "group",
            left: 50,
            top: 30,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#fef1f3",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "走强",
                  fill: "#c12c1f",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
          {
            type: "group",
            right: 220,
            top: 30,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#fef1f3",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "领涨",
                  fill: "#c12c1f",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
          {
            type: "group",
            left: 50,
            bottom: 50,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#eefff6",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "领跌",
                  fill: "#09b3bc",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
          {
            type: "group",
            right: 220,
            bottom: 50,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#eefff6",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "走弱",
                  fill: "#09b3bc",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option, true);
    },
  },
  watch: {
    fanDays: {
      handler: function (val, oldVal) {
        this.getChartData();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
