<template>
  <div>
    <div v-show="!isTable">
      <div
        style="
          z-index: 11;
          position: absolute;
          top: 20px;
          right: 15px;
          font-size: 13px;
          opacity: 0.7;
          color: #cf0000;
        "
      >
        点击柱状图中的元素可跳转到成分明细
      </div>

      <div
        :id="typeId"
        style="height: 300px; width: calc(100vw - 170px); z-index: 10"
        v-if="!mini"
      ></div>
      <div
        :id="typeId"
        style="height: 300px; width: calc(100vw - 500px); z-index: 10"
        v-if="mini"
      ></div>
    </div>

    <div v-show="isTable">
      <div
        style="
          z-index: 11;
          position: absolute;
          top: 15px;
          right: 15px;
          font-size: 13px;
          opacity: 0.7;
          color: #cf0000;
        "
      >
        点击"N日动量"按钮可排序，点击表项目可查看成分明细
        <vxe-input
          style="width: 100px"
          v-model="filterName"
          type="search"
          placeholder="全文搜索"
          class="ml-1"
        ></vxe-input>
      </div>
      <vxe-table
        :data="momPageData"
        style="width: 100%; margin-top: 20px"
        class="mytable-scrollbar"
        :border="false"
        resizable
        :scroll-y="{ enabled: false }"
        show-overflow
        show-header-overflow
        highlight-hover-row
        ref="chartTable"
        max-height="100000"
        size="small"
        :empty-render="{ name: 'NotData' }"
        align="left"
        @cell-click="cellClick"
        @sort-change="sortChangeEvent"
        :tree-config="{
          transform: true,
          rowField: 'id',
          parentField: 'parentId',
          accordion: true,
        }"
      >
        <vxe-column
          fixed="left"
          min-width="80"
          field="sec_name"
          title="标签名称"
        >
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ row.sec_name ? row.sec_name : "-" }}
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="1日收益率" field="MOM_1D" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.MOM_1D)">{{
                $stockHelpers.getPercent(row.MOM_1D)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="3日收益率" field="MOM_3D" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.MOM_3D)">{{
                $stockHelpers.getPercent(row.MOM_3D)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="5日收益率" field="MOM_5D" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.MOM_5D)">{{
                $stockHelpers.getPercent(row.MOM_5D)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="10日收益率" field="MOM_10D" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.MOM_10D)">{{
                $stockHelpers.getPercent(row.MOM_10D)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="20日收益率" field="MOM_20D" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.MOM_20D)">{{
                $stockHelpers.getPercent(row.MOM_20D)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="60日收益率" field="MOM_60D" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.MOM_60D)">{{
                $stockHelpers.getPercent(row.MOM_60D)
              }}</span>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager
        :current-page="pageInfo.currentPage"
        :page-size="10"
        :total="this.pageInfo.totalResult"
        :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Total']"
        @page-change="
          (value) =>
            getPage(value, this.momentumDetail, [$refs['chartTable']], pageInfo)
        "
      >
      </vxe-pager>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import XEUtils from "xe-utils";
export default {
  name: "ChartWithTable",
  components: {},
  props: {
    currentBarTab: 0,
    barData: [],
    fullData: [],
    isTable: false,
    momentumDetail: [],
    typeId: "",
    mini: false,
  },
  data() {
    return {
      myChart: null,
      MOM_typeArr: [
        "MOM_1D",
        "MOM_3D",
        "MOM_5D",
        "MOM_10D",
        "MOM_20D",
        "MOM_60D",
      ],
      filterName: "",
      MOM_type: "MOM_1D",
      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        totalResult: this.momentumDetail.length,
      },
      momPageData: [],
      sortInfo: {
        field: "MOM_1D",
        order: "desc",
      },
    };
  },
  mounted() {
    let chartDom = document.getElementById(this.typeId);
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
    //this.getList();

    this.myChart.getZr().on("click", (params) => {
      let pointInPixel = [params.offsetX, params.offsetY];
      if (this.myChart.containPixel("grid", pointInPixel)) {
        let xIndex = this.myChart.convertFromPixel({ seriesIndex: 0 }, [
          params.offsetX,
          params.offsetY,
        ])[0];
        //console.log(this.myChart.getOption().dataset[0].source[xIndex + 1][0]);
        this.$emit(
          "selectItem",
          this.myChart.getOption().dataset[0].source[xIndex + 1][0]
        );
      }
    });
  },
  methods: {
    fetchData() {
      let data = [["", null]];

      this.barData[this.MOM_type.toLowerCase()].forEach((item) => {
        //console.log(item.sec_name);
        data.push([item.sec_name, (item[this.MOM_type] * 100).toFixed(2)]);
      });

      //console.log(data);
      let option = {
        grid: {
          top: "20px",
          left: "10px",
          right: "10px",
          bottom: "0%",
          containLabel: true,
        },
        dataZoom: [
          {
            show: false,
            realtime: true,
            start: 1,
            end: 999,
          },
          {
            type: "inside",
            realtime: true,
            start: 1,
            end: 999,
          },
        ],
        dataset: [
          {
            dimensions: ["sec_name", "value"],
            source: data,
          },
          {
            transform: {
              type: "sort",
              config: { dimension: "value", order: "desc" },
            },
          },
        ],
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 0,
            fontSize: 10,
            formatter: function (value) {
              return value.split("").join("\n");
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value} %",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let tar = params[0];
            let rank = 1;
            //console.log(this.barData[this.MOM_type.toLowerCase()]);
            this.barData[this.MOM_type.toLowerCase()].forEach((item, index) => {
              if (item.sec_name == tar.data[0]) {
                rank = index + 1;
              }
            });
            return (
              "<b>动量</b>:" +
              tar.data[1] +
              "%" +
              "<br><b>所属行业</b>:" +
              tar.data[0] +
              "<br><b>排名</b>:" +
              rank
            );
          },
        },
        series: {
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.1)",
          },
          encode: {
            x: "sec_name",
            y: "value",
            tooltip: "value",
          },
          //barWidth: 10,
          barGap: "100%",
          datasetIndex: 1,
          itemStyle: {
            color: function (param) {
              //console.log(param);
              return param.data[1] > 0 ? "#ef534f" : "#2aa49d";
            },
          },
        },
      };

      this.myChart.setOption(option);
    },
    getPage(pager, tableData, table, pageObj) {
      pageObj.currentPage = pager.currentPage;
      this.momPageData = this.$tableHelpers.getPageWithSort(
        pager,
        tableData,
        table,
        this.sortInfo
      );
    },
    cellClick({ row }) {
      console.log(row.sec_name);
      this.$emit("selectItem", row.sec_name);
    },
    sortChangeEvent({ sortList }) {
      //console.log(sortList);
      this.sortInfo = sortList[0];

      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      const searchProps = ["sec_name"];
      const rest = this.momentumDetail.filter((item) =>
        searchProps.some(
          (key) =>
            XEUtils.toValueString(item[key]).toLowerCase().indexOf(filterName) >
            -1
        )
      );

      this.momPageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        rest,
        [this.$refs.chartTable],
        this.sortInfo
      );
    },
  },
  watch: {
    barData: function (val) {
      this.fetchData();
    },
    currentBarTab: function (val) {
      this.MOM_type = this.MOM_typeArr[val];
      this.fetchData();

      this.$refs.chartTable.sort(this.MOM_type, "desc");
      this.sortInfo = {
        field: this.MOM_type,
        order: "desc",
      };
      this.momPageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        this.momentumDetail,
        [this.$refs.chartTable],
        this.sortInfo
      );
    },
    momentumDetail: function (val) {
      if (val.length > 0) {
        this.pageInfo.totalResult = val.length;
        this.momPageData = this.$tableHelpers.getPageWithSort(
          this.pageInfo,
          this.momentumDetail,
          [this.$refs.chartTable],
          this.sortInfo
        );
        this.$refs.chartTable.sort(this.MOM_type, "desc");
      }
    },
    filterName: function (val) {
      const filterName = XEUtils.toValueString(val).trim().toLowerCase();
      const searchProps = ["sec_name"];
      const rest = this.momentumDetail.filter((item) =>
        searchProps.some(
          (key) =>
            XEUtils.toValueString(item[key]).toLowerCase().indexOf(filterName) >
            -1
        )
      );

      this.$refs.chartTable.clearSort();

      if (val) {
        this.pageInfo.totalResult = rest.length;
      } else {
        this.pageInfo.totalResult = this.momentumDetail.length;
      }

      this.momPageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        rest,
        [this.$refs.chartTable],
        this.sortInfo
      );
    },
  },
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
